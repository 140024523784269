import React, { useState } from 'react';
import moment from 'moment';


const SimplePopupFixedSize = () => {
  const now = new Date();
  const dateLimit = moment('2025-01-29T23:59:59.983Z', 'YYYY-MM-DDTHH:mm:ss.SSSZ');

  const capacityIsStillAvailable = false;
  const isPopUpActive = capacityIsStillAvailable && dateLimit.isAfter(now);
  const [isOpen, setIsOpen] = useState(isPopUpActive);

  // change Image in styles.css

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {isOpen && (
        <div className="popup-fixed-size">
				<button className="popup-fixed-size-button">
					<a href="https://just4keepers.dtap.cz/register/?link=11">Přihláška</a>
				</button>

          <button className="popup-close-button left" onClick={togglePopup}>X</button>

        </div>
      )}
    </div>
  );
};

export default SimplePopupFixedSize;
