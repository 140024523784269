import React from "react";

import Header from '../../header';
import Footer from '../../footer';

export default class PaymentConditions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      photoUrl: undefined,
      campsData: [],
      photoGallery: [],
      isLoading: true,
    };
  }

  render() {
    return (
      <div>
            <Header />

            <div class="view textArticle camps">

            <h3>Platební a storno podmínky</h3>

              <p>
                <h5>O platbě kempů</h5>
                <p>
                  Rezervace místa na kempu je provedena na základě vyplněné přihlášky a zaplacením celé částky 7 500 Kč Libice nad Doubravou / 6 450 Kč Kobylí/Bořetice / 5000 Kč Luka nad Jihlavou, která musí proběhnout nejdéle do 21 pracovních dnů od přijetí emailu s detaily platby.
                </p>
                <p>
                  <b>Číslo účtu spolku</b>: 90949622/5500.
                </p>
                <p>
                  Zaplatit můžete po částech, nebo rovnou celkovou částku.
                </p>
                <p>
                  Váš zaměstnavatel Vám může přispět na pobyt pro děti. Informujte se u něj ohledně této možnosti. Potvrzení o přihlášení Vašeho dítěte na tábor Vám rádi vystavíme a fakturu pro Vašeho zaměstnavatele vystavíme na základě jeho písemné objednávky s uvedením jeho adresy a IČO popř. bankovního spojení.
                </p>
                <p>
                V případě potřeby Vám vystavíme potvrzení o účasti dítěte na letním táboře pro zdravotní pojišťovnu.
                </p>
            </p>


              <p>
                <h5>Storno poplatky</h5>
                <p>
                  Pokud za Vámi přihlášené dítě zajistíte náhradníka, je storno poplatek 0 Kč.
                </p>
                <p>
                Zrušení přihlášky 30 až 8 dnů před začátkem tábora je storno poplatek 50 % z celkové částky za kemp.
                </p>
                <p>
                Zrušení přihlášky 7 a méně dnů před začátkem tábora je storno poplatek 100 % z celkové částky za kemp.
                </p>
                <p>
                Při onemocnění dítěte v průběhu kempu a nutnosti odjezdu se peníze nevrací.
                </p>
            </p>

            </div>

            <Footer />
      </div>
    );
  }

}
