import React, { Component } from "react";

import {
  Route,
  HashRouter
} from "react-router-dom";

// Google Analytics tracking
import ReactGA from 'react-ga';


// Views
import Home from "./Home";
import News from "./news/News";
import References from "./References";
import Contacts from "./Contacts";
import ArticleDetail from "./ArticleDetail";
import ArticleNewsDetail from "./news/ArticleNewsDetail";
import TrainingLocationDetail from "./TrainingLocations";
import ProfileDetail from "./ProfileDetail";
import TrainingVideos from "./sections/TrainingVideos";
import About from "./sections/about/About";
import SummerCamps from "./camps/SummerCamps";
import AutumnCamps from "./camps/AutumnCamps";
import WinterCamps from "./camps/WinterCamps";
import AprilEvent from "./camps/AprilEvent";
import UkonceniPrazdnin from "./camps/UkonceniPrazdnin";
import GoalkeepersDay from "./camps/GoalkeepersDay";
import SilvesterDay from "./camps/SilvesterDay";
import TryTraining from "./sections/try-training/TryTraining";
import Blogs from "./sections/blogs/Blogs";
import Coaches from "./sections/coaches/Coaches"

import PaymentConditions from "./common/PaymentConditions";

import PrivacyPolicyAndSecurity from "../components/data/PrivacyPolicyAndSecurity";

// Admin
import AddNewsPage from "./admin/AddNewsPage";
import AddCampsPage from "./admin/AddCampsPage";

// Admin
import PrivateDocsPage from "./private/docs/PrivateDocsPage";

function fireTracking() {
    ReactGA.pageview(window.location.hash);
}

class Main extends Component {

  render() {
    ReactGA.initialize('UA-135460781-2');
    ReactGA.pageview("homepage");

    return (
		<HashRouter onUpdate={fireTracking}  >
          <div className="content">
            <Route exact path="/" component={Home}/>
            <Route path="/news" component={News}/>
            <Route path="/contacts" component={Contacts}/>
            <Route path="/references" component={References}/>
            <Route exact path="/slider-report/:id" component={ArticleDetail}/>
            <Route exact path="/brankarskekempy/ukonceni-prazdnin" component={UkonceniPrazdnin}/>
            <Route exact path="/brankarskekempy/letni" component={SummerCamps}/>
            <Route exact path="/brankarskekempy/majove-mini-silenstvi" component={AprilEvent}/>
            <Route exact path="/brankarskekempy/podzimni" component={AutumnCamps}/>
            <Route exact path="/brankarskekempy/zimni" component={WinterCamps}/>
            <Route exact path="/brankarskekempy/den-brankaru" component={GoalkeepersDay}/>
            <Route exact path="/brankarskekempy/silvester" component={SilvesterDay}/>
            <Route exact path="/news-report/:title" component={ArticleNewsDetail}/>
            <Route exact path="/profile/:id" component={ProfileDetail}/>
            <Route path="/training-videos" component={TrainingVideos}/>
            <Route exact path="/training-location/:city" component={TrainingLocationDetail}/>
            <Route exact path="/privacyPolicyAndSecurity" component={PrivacyPolicyAndSecurity}/>
            <Route exact path="/eshop" component={() => { window.location = 'https://j4ksports.cz/'; return null;} }/>
            <Route exact path="/admin/news" component={AddNewsPage}/>
            <Route exact path="/admin/camps" component={AddCampsPage}/>
            <Route exact path="/private/docs" component={PrivateDocsPage}/>
            <Route exact path="/about" component={About}/>
            <Route exact path="/try-training" component={TryTraining}/>
            <Route exact path="/blogs" component={Blogs}/>
            <Route exact path="/coaches" component={Coaches}/>
            <Route exact path="/payment-conditions" component={PaymentConditions}/>
          </div>
      </HashRouter>

    );
  }
}

export default Main;
